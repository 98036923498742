import { getGatsbyImageData } from "@imgix/gatsby"
import type * as pt from "@prismicio/types"
import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyAllNewsPosts } from "../../../slices/PageDataBodyAllNewsPosts"

type Post = React.ComponentPropsWithoutRef<
	typeof PageDataBodyAllNewsPosts
>["posts"][number]

const post = (data: Partial<Post> = {}): Post => {
	return {
		href: "#",
		image: getGatsbyImageData({
			width: 400,
			layout: "fullWidth",
			breakpoints: [320, 400],
			src: "https://images.unsplash.com/photo-1486406146926-c627a92ad1ab",
			aspectRatio: 1513 / 1008,
		}),
		imageAlt: "",
		summary: [
			{
				type: "paragraph",
				spans: [],
				text: lorem.sentences(2),
			},
		] as pt.RichTextField,
		title: lorem.words(3),
		tags: [],
		publishedAt: new Date().toDateString(),
		...data,
	}
}

const Component = () => {
	return (
		<CmsGuideLayout>
			<PageDataBodyAllNewsPosts
				posts={[
					post(),
					post({ image: undefined, imageAlt: undefined }),
					post({ image: undefined, imageAlt: undefined }),
				]}
				currentPage={1}
				totalPages={3}
				paginationIndexPath="/news"
				categories={[lorem.words(1), lorem.words(2), lorem.words(1)]}
			/>
		</CmsGuideLayout>
	)
}

export default Component
